<template>
    <div class="corp-info flex-item" v-loading="loading">
        <el-row :gutter="40" v-for="(parent, i) in list" :key="'drugInfo' + i">
            <el-col :span="item.span || parent.span" v-for="item in parent.children" :key="item.key">
                <div class="corp-info-item">
                    <p class="title">{{ item.value }}：</p>
                    <p class="content" :class="{ clickable: item.link }" @click="() => { item.link(form) }">
                        {{ form[item.key] || form[item.key] === 0 ? form[item.key] : '&nbsp;' }}</p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import searchMixin from './searchMixin'
export default {
    mixins: [searchMixin],
    props: {
        baseQuery: {
            default: () => ({})
        },
    },
    data() {
        return {
            loading: false,
            form: {},
            list: [
                {
                    span: 6,
                    children: [
                        { key: 'comName', value: '药品通用名' },
                        { key: 'def01', value: '国产批文', link: this.goChina, },
                        { key: 'def07', value: '进口批文', link: this.goForeign, },
                        { key: 'def03', value: '研发申报', link: this.goRegister },
                    ]
                },
                {
                    span: 6,
                    children: [
                        { key: 'def02', value: '国内生产企业数', link: this.goChina, },
                        { key: 'def08', value: '国外生产企业数', link: this.goForeign },
                        { key: 'def05', value: '基药', link: this.goBase },
                        { key: 'def21', value: '基药规格' },
                    ]
                },
                {
                    span: 6,
                    children: [
                        { key: 'def04', value: '医保类型', link: this.goHealth },
                        { key: 'def06', value: '中药保护品种' },
                        { key: 'def09', value: '是否有销售' },
                        { key: 'sale', value: '历年销售情况', link: this.goDrugTotal },
                    ]
                },
                {
                    span: 12,
                    children: [
                        { key: 'treatBig', value: '大类' },
                        { key: 'treatSmall', value: '亚类' },
                    ]
                },
                {
                    span: 12,
                    children: [
                        { key: 'treatThree', value: '三类' },
                        { key: 'treatFour', value: '四类' },
                    ]
                },
            ],
            cndCluster: {
                op: 'or',
                cnds: [
                    { name: 'def01', op: 'ne', value: '0' },
                    { name: 'def07', op: 'ne', value: '0' },
                    { name: 'def02', op: 'ne', value: '0' },
                    { name: 'def08', op: 'ne', value: '0' },
                    { name: 'def03', op: 'ne', value: '0' },
                    { name: 'def04', op: 'isNull' },
                    { name: 'def05', op: 'ne', value: '否' },
                    { name: 'def21', op: 'isNull' },
                    { name: 'def06', op: 'ne', value: '否' },
                    { name: 'def09', op: 'ne', value: '无' },
                ]
            },
        }
    },
    created() {
        this.loading = true
        this.form = {}
        this.$api.post('indexDrug/aimBaseDrugs/queryAimBaseDrugsPage', {
            page: { pageNo: 1, pageSize: 20 },
            params: this.baseQuery,
            cndCluster: this.cndCluster
        })
            .then(res => {
                if (res.data.records && res.data.records.length > 0) {
                    this.form = res.data.records[0]
                    this.$set(this.form, 'sale', '查看')

                    this.$api.get('setting/aimBase/queryComTreat', { comCode: this.form.comCode })
                        .then(res => {
                            this.$set(this.form, 'treatBig', res.data.treatBig)
                            this.$set(this.form, 'treatSmall', res.data.treatSmall)
                            this.$set(this.form, 'treatThree', res.data.treatThree)
                            this.$set(this.form, 'treatFour', res.data.treatFour)
                        })
                }
            })
            .finally(() => {
                this.loading = false
            })
    },
    methods: {
        exportInfo(txt = '确定导出吗') {
            this.$help.exportInfo(txt, 'indexDrug/aimBaseDrugs/exportQueryAimBaseDrugsPage', { params: this.baseQuery, cndCluster: this.cndCluster })
        },
    }
}
</script>